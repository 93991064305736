<template>
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth">
      <div class="row flex-grow">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left p-5">
            <div class="brand-logo">
              <img src="@/assets/images/logo_transparent.png">
            </div>
            <h4>Hello! let's get started</h4>
            <h6 class="font-weight-light">Sign in to continue.</h6>
            <form class="pt-3">
              <div class="form-group">
                <input type="text" class="form-control form-control-lg" v-model="$v.username.$model" id="exampleInputEmail1" placeholder="Username" :class="{ 'is-invalid': $v.username.$error }">
                <div v-if="$v.username.$error" class="invalid-feedback">
                  <span v-if="!$v.username.required">Please enter username</span>
                </div>                
              </div>
              <div class="form-group input-group">
                <input :type="show ? 'text' : 'password'" class="form-control form-control-lg" v-model="$v.password.$model" id="exampleInputPassword1" placeholder="Password" :class="{ 'is-invalid': $v.password.$error }">
                <span class="input-group-text">
                    <i :class="show ? 'mdi mdi-eye-off' : 'mdi mdi-eye'" id="togglePassword"
                   style="cursor: pointer" @mousedown="show = !show" @mouseup="show = !show" @touchstart="show = !show" @touchend="show = !show" ></i>
                </span>
                <div v-if="$v.password.$error" class="invalid-feedback">
                  <span v-if="!$v.password.required">Please enter password</span>
                </div>
              </div>

              <div clas="col-12 col-lg-12">
                  <div class="form-check">
                      <router-link to="/forgot-password" class="auth-link text-black">Forgot password?</router-link>
                  </div>
              </div>

              <div class="mt-3">
                <b-spinner v-if="is_btn_spinner" label=""></b-spinner>
                <button v-else type="button" class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" @click="LogIn">SIGN IN</button>

              </div>
            </form>

            <div class="col-12 col-lg-12 mt-4 mb-4 mb-lg-4 text-center text-lg-center">
                <a href="https://softpital.in/terms-and-conditions/" target="_blank">Terms of Service</a>&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;
                <a href="https://softpital.in/privacy-policy/" target="_blank">Privacy Policy</a>
            </div>
            <div class="col-12 col-lg-12 mb-4 mb-lg-4 text-center text-lg-center">
                <a href="https://softpital.in/refund-policy/" target="_blank">Refund Policy</a>
            </div>
            <div class="col-12 col-lg-12 text-center text-lg-center">
                <span class="text-muted">Copyright © Softpital 2024</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import Footer from "@/components/Footer.vue";
import string from "../constant/strings.js"
import Bowser from 'bowser';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export default {
  title: string.PAGE_TITLE_SIGN_IN,
  components: {Footer,},
  data() {
    return {
      username:'',
      password:'',
      is_btn_spinner:false,
      show:false,
      location:'',
      deviceId:'',
    };
  },
  validations: {    
    password: {required},
    username:{required}
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  mounted() {
    this.getDeviceId();
    this.getLocation();
    if(this.$auth.user){
      if(this.$auth.user.is_master_user){
        this.$router.push({ name: "masteradmin" });
      } else {
        this.$router.push({ name: "home" });
      }
    }    
  },
   methods: {    
    LogIn() {

      this.$v.$touch();
      if (this.$v.password.$invalid) {
        this.is_btn_spinner = false;
        return;
      } else {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const result = browser.getBrowser();
        const platform = browser.getPlatform();
        let loginData = {
          email: this.username,
          password: this.password,
          browser_details: platform.type + " " + result.name + result.version,
          system_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          location: this.location,
          device_id: this.deviceId,
          logout_by_device_id: false,
        };
        this.is_btn_spinner = true;
        this.$auth.login(loginData, (type) => {
          this.is_btn_spinner = false;
          if (this.prevRoute && this.prevRoute.name){
            if(this.$auth.user.is_master_user){
                this.$router.push({ name: "masteradmin" });
            } else {
                this.$router.push({ name: "home" });
            }
          } else {
            this.is_btn_spinner = false;
            this.$router.push({ name: type });
          }
        });
      }
    },
    getLocation() {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by this browser.");
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    this.location = position.coords.latitude + ", " + position.coords.longitude
                },
                (error) => {
                    reject(error.message);
                }
            );
        });
    },
    getDeviceId() {
        // Check if device_id is already stored in cookies
        const storedDeviceId = Cookies.get('device_id');

        if (storedDeviceId) {
          // Use the stored device_id if it exists
          this.deviceId = storedDeviceId;
        } else {
          // Generate a new UUID and store it in cookies
          this.deviceId = uuidv4();
          Cookies.set('device_id', this.deviceId, { expires: 365 }); // Expires in 365 days
        }
      },
  },
};
</script>